import Ruchita_Nathani_Resume from '../assets/pdf/Ruchita_Nathani_Resume.pdf'
import image from '../assets/png/placeholder.png'

export const headerData = {
    name: 'Ruchita Nathani',
    title: "Full Stack Developer",
    desciption:"Dedicated software engineer with a deep-seated passion for web development, database queries, backend configuration, and data administration.",
    image: image,
    resumePdf: Ruchita_Nathani_Resume
}
